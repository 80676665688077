import { ICache } from '@halo-data/utilities';
import { UserInfo } from 'halo-infinite-api';

export class CombinedUserCache implements ICache<UserInfo, string, []> {
  constructor(
    private gamerTagCache: ICache<UserInfo, string>,
    private xuidCache: ICache<UserInfo, string>
  ) {}

  get(key: string, signal?: AbortSignal): Promise<UserInfo>;
  get(keys: string[], signal?: AbortSignal): Promise<UserInfo>[];
  get(
    keyOrKeys: string | string[],
    signal?: AbortSignal
  ): Promise<UserInfo> | Promise<UserInfo>[] {
    if (Array.isArray(keyOrKeys)) {
      const xuids: string[] = [];
      const gamertags: string[] = [];
      keyOrKeys.forEach((x) => {
        if (/^xuid\(\d+\)$/i.test(x)) {
          xuids.push(x);
        } else {
          gamertags.push(x);
        }
      });
      return [
        ...this.gamerTagCache.get(gamertags, signal),
        ...this.xuidCache.get(xuids, signal),
      ];
    } else {
      if (/^xuid\(\d+\)$/i.test(keyOrKeys)) {
        return this.xuidCache.get(keyOrKeys, signal);
      } else {
        return this.gamerTagCache.get(keyOrKeys, signal);
      }
    }
  }
  set(_key: string, value: UserInfo): void {
    this.gamerTagCache.set(value.gamertag, value);
    this.xuidCache.set(value.xuid, value);
  }
  delete(key: string): void {
    this.gamerTagCache.delete(key);
    this.xuidCache.delete(key);
  }
}
