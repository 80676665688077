import { ICache, MemoryCache } from '@halo-data/utilities';
import { IPolicy } from 'cockatiel';
import {
  HaloInfiniteClient,
  MatchType,
  PlayerMatchHistory,
} from 'halo-infinite-api';

export class MatchPageCache
  implements
    ICache<
      PlayerMatchHistory[],
      { start: number; xuid: string; pageSize: number }
    >
{
  private innerMatchPageCache: ICache<
    PlayerMatchHistory[],
    {
      start: number;
      xuid: string;
    },
    []
  >;
  constructor(haloInfiniteClient: HaloInfiniteClient, requestPolicy: IPolicy) {
    this.innerMatchPageCache = new MemoryCache({
      cacheExpirationMs: 15 * 1000,
      keyTransformer: (key: { start: number; xuid: string }) =>
        `${key.xuid}.${key.start}`,
      fetchOneFn: (key: { start: number; xuid: string }, signal) =>
        requestPolicy.execute(
          (ctx) =>
            haloInfiniteClient.getPlayerMatches(
              key.xuid,
              MatchType.All,
              25,
              key.start,
              { signal: ctx.signal }
            ),
          signal
        ),
    });
  }

  get(
    key: { start: number; xuid: string; pageSize: number },
    signal?: AbortSignal | undefined
  ): Promise<PlayerMatchHistory[]>;
  get(
    keys: { start: number; xuid: string; pageSize: number }[],
    signal?: AbortSignal | undefined
  ): Promise<PlayerMatchHistory[]>[];
  get(
    keyOrKeys:
      | { start: number; xuid: string; pageSize: number }
      | { start: number; xuid: string; pageSize: number }[],
    signal?: AbortSignal | undefined
  ): Promise<PlayerMatchHistory[]> | Promise<PlayerMatchHistory[]>[] {
    return Array.isArray(keyOrKeys)
      ? keyOrKeys.map((k) => this.getOne(k, signal))
      : this.getOne(keyOrKeys, signal);
  }
  private async getOne(
    key: { start: number; xuid: string; pageSize: number },
    signal: AbortSignal | undefined
  ) {
    const startPage = Math.floor(key.start / 25);
    const endPage = Math.ceil((key.start + key.pageSize) / 25);
    const uncutPages = await Promise.all(
      Array.from({ length: endPage - startPage }, (_, i) =>
        this.innerMatchPageCache.get(
          {
            start: (startPage + i) * 25,
            xuid: key.xuid,
          },
          signal
        )
      )
    );
    const slicedPages = uncutPages
      .flat()
      .slice(
        key.start - startPage * 25,
        key.start - startPage * 25 + key.pageSize
      );
    return slicedPages;
  }
  set(): void {
    throw new Error('Method not implemented.');
  }
  delete(): void {
    throw new Error('Method not implemented.');
  }
}
