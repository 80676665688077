import {
  compareXuids,
  ICache,
  MemoryCache,
  NoCache,
  unwrapXuid,
  wrapXuid,
} from '@halo-data/utilities';
import { IPolicy } from 'cockatiel';
import {
  AssetKind,
  AssetKindTypeMap,
  AssetVersionLink,
  GameVariantCategory,
  HaloInfiniteClient,
  MapAsset,
  MapModePairAsset,
  MatchSkill,
  MatchStats,
  PlayerMatchHistory,
  Playlist,
  PlaylistAsset,
  ResultContainer,
  UgcGameVariantAsset,
  UserInfo,
  XboxClient,
} from 'halo-infinite-api';
import { CombinedUserCache } from './combined-user-cache';
import { MatchPageCache } from './match-page-cache';
export class HaloCaches {
  usersCache: ICache<UserInfo, string>;
  matchStatsCache: ICache<MatchStats<GameVariantCategory>, string>;
  matchSkillsCache: ICache<
    ResultContainer<MatchSkill<0>>,
    {
      matchId: string;
      playerId: string;
    }
  >;
  playlistCache: ICache<Playlist, string>;
  matchPageCache: ICache<
    PlayerMatchHistory[],
    { start: number; xuid: string; pageSize: number }
  >;
  mapCache: ICache<
    MapAsset | AssetVersionLink,
    Omit<AssetVersionLink, 'AssetKind'>
  >;
  gameVariantCache: ICache<
    AssetVersionLink | UgcGameVariantAsset,
    Omit<AssetVersionLink, 'AssetKind'>
  >;
  playlistVersionCache: ICache<
    AssetVersionLink | PlaylistAsset,
    Omit<AssetVersionLink, 'AssetKind'>
  >;
  mapModePairCache: ICache<
    AssetVersionLink | MapModePairAsset,
    Omit<AssetVersionLink, 'AssetKind'>
  >;

  constructor(
    haloInfiniteClient: HaloInfiniteClient,
    xboxClient: XboxClient,
    requestPolicy: IPolicy
  ) {
    const gamerTagCache = new MemoryCache({
      maxEntries: 1,
      rollingExpiration: true,
      keyTransformer: (gamertag: string) => gamertag.toLowerCase(),
      fetchOneFn: async (gamertag: string, signal) => {
        const result = await requestPolicy.execute(
          (ctx) => haloInfiniteClient.getUser(gamertag, { signal: ctx.signal }),
          signal
        );

        if (result.gamertag.toLowerCase() !== gamertag.toLowerCase()) {
          // Halo has returned the wrong gt. Cool, let's hack around that.
          const searchResults = await xboxClient.searchUsers(gamertag, {
            signal,
          });
          const searchResult = searchResults.find(
            (r) => r.gamertag.toLowerCase() === gamertag.toLowerCase()
          );
          if (!searchResult) {
            throw new Error(`Failed to find user ${gamertag}`);
          }
          return xuidCache.get(wrapXuid(searchResult.xuid), signal);
        }

        xuidCache.set(result.xuid, result);
        return result;
      },
    });
    const xuidCache = new MemoryCache({
      maxEntries: 1000,
      rollingExpiration: true,
      keyTransformer: (xuid: string) => unwrapXuid(xuid),
      fetchManyFn: (xuids: string[], signal) =>
        requestPolicy.execute(
          (ctx) => haloInfiniteClient.getUsers(xuids, { signal: ctx.signal }),
          signal
        ),
      resultSelector: (items, xuid) => {
        const item = items.find((i) => compareXuids(i.xuid, xuid));
        if (!item) {
          throw new Error(`Failed to find user ${xuid}`);
        }
        gamerTagCache.set(item.gamertag, item);
        return item;
      },
    });
    this.usersCache = new CombinedUserCache(gamerTagCache, xuidCache);

    this.matchStatsCache = new NoCache({
      fetchOneFn: (matchId: string, signal) =>
        requestPolicy.execute(
          (ctx) =>
            haloInfiniteClient.getMatchStats(matchId, { signal: ctx.signal }),
          signal
        ),
    });
    this.matchSkillsCache = new MemoryCache({
      maxEntries: 8000,
      rollingExpiration: true,
      async fetchManyFn(keys: { matchId: string; playerId: string }[], signal) {
        const matchGroups = keys.groupBy((k) => k.matchId);
        const results = await Promise.all(
          Array.from(matchGroups.entries())
            .filter(([, group]) => group.length > 0)
            .map(async ([matchId, group]) => {
              return {
                matchId,
                skills: await requestPolicy.execute(
                  (ctx) =>
                    haloInfiniteClient.getMatchSkill(
                      matchId,
                      group.map((p) => p.playerId),
                      { signal: ctx.signal }
                    ),
                  signal
                ),
              };
            })
        );
        return results;
      },
      resultSelector: (results, key) => {
        const result = results.find((r) => r.matchId === key.matchId);
        if (!result) {
          throw new Error(`Failed to find match ${key.matchId}`);
        }
        const skill = result.skills.find((s) => s.Id === key.playerId);
        if (!skill) {
          throw new Error(
            `Failed to find skill ${key.matchId}.${key.playerId}`
          );
        }
        return skill;
      },
      keyTransformer: (key) => `${key.matchId}.${key.playerId}`,
    });
    this.playlistCache = new NoCache({
      fetchOneFn: (playlistId: string, signal) =>
        requestPolicy.execute(
          (ctx) =>
            haloInfiniteClient.getPlaylist(playlistId, { signal: ctx.signal }),
          signal
        ),
    });

    [
      this.mapCache,
      this.gameVariantCache,
      this.playlistVersionCache,
      this.mapModePairCache,
    ] = (
      [
        {
          name: 'map',
          assetKind: AssetKind.Map,
        },
        {
          name: 'game variant',
          assetKind: AssetKind.UgcGameVariant,
        },
        {
          name: 'playlist',
          assetKind: AssetKind.Playlist,
        },
        {
          name: 'map mode pair',
          assetKind: AssetKind.MapModePair,
        },
      ] as const
    ).map(
      <const T extends keyof AssetKindTypeMap>({
        assetKind,
      }: {
        name: string;
        assetKind: T;
      }) =>
        new MemoryCache({
          keyTransformer: (key: Omit<AssetVersionLink, 'AssetKind'>) =>
            `${key.AssetId}.${key.VersionId}`,
          fetchOneFn: (key, signal) =>
            requestPolicy.execute(
              (ctx) =>
                haloInfiniteClient
                  .getSpecificAssetVersion(
                    assetKind,
                    key.AssetId,
                    key.VersionId,
                    {
                      signal: ctx.signal,
                    }
                  )
                  .catch(() => {
                    return { key, AssetKind: assetKind };
                  }),
              signal
            ),
        })
    ) as [
      MemoryCache<
        AssetKindTypeMap[typeof AssetKind.Map] | AssetVersionLink,
        Omit<AssetVersionLink, 'AssetKind'>
      >,
      MemoryCache<
        AssetKindTypeMap[typeof AssetKind.UgcGameVariant] | AssetVersionLink,
        Omit<AssetVersionLink, 'AssetKind'>
      >,
      MemoryCache<
        AssetKindTypeMap[typeof AssetKind.Playlist] | AssetVersionLink,
        Omit<AssetVersionLink, 'AssetKind'>
      >,
      MemoryCache<
        AssetKindTypeMap[typeof AssetKind.MapModePair] | AssetVersionLink,
        Omit<AssetVersionLink, 'AssetKind'>
      >
    ];

    this.matchPageCache = new MatchPageCache(haloInfiniteClient, requestPolicy);
  }
}
